import "./src/styles/global.css";
import "./src/styles/variables.css";
import "./src/styles/classColors.css";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const path = location.pathname.toLowerCase();
  if (path === `/articles/` || path === `/articles`) {
    return false;
  }

  return true;
};
